import React from 'react';

// import PropTypes from 'prop-types';

class Specialists extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div>
          Specialists
        </div>
      </React.Fragment>
    );
  }
}

export default Specialists;
